<template>
  <el-dialog    title="归还操作页面" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="returnFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="借走人姓名" width="120px">
            <el-input width="20px" v-model="returnFrom.borrowAlias" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="借走金额" width="120px">
            <el-input width="20px" v-model="returnFrom.borrowMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="借走时间" width="120px">
            <el-input width="20px" v-model="returnFrom.borrowData" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="预计归时间" width="120px">
            <el-input width="20px" v-model="returnFrom.preReturnData" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
      <el-col :span="6">
          <el-form-item label="是否一次归还" width="120px" prop="isDisposable">
            <el-radio v-model="returnFrom.isDisposable" label="1">是</el-radio>
            <el-radio v-model="returnFrom.isDisposable" label="2">否</el-radio>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="归还时间" width="120px">
            <el-date-picker
              v-model="returnFrom.returnData"
              align="right"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="本次还金额" width="120px">
            <el-input-number width="20px" v-model="returnFrom.returnMoney" @change="changeMoney" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="差异金额" width="120px">
            <el-input width="20px" v-model="returnFrom.differMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="归还备注" width="120px">
            <el-input width="20px" v-model="returnFrom.returnRemark" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="subitmButton()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  selectBorrowById,
  returnMoney,
  clearingMoney
} from "@/api/capital/bank/bankBorrow";
export default {
  name: "ReturnBorrow",
  data() {
    return {
      dialogVisible: false,
      returnFrom: {},
      type: 1,
      title:null
    };
  },
  methods: {
    //打开弹窗
    show(id, type) {
      this.type = type;
      this.dialogVisible = true;
      this.selectBorrowByButton(id);
    },
    selectBorrowByButton(id) {
      selectBorrowById(id).then(res => {
        this.returnFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    subitmButton() {
      if (this.returnFrom.differMoney != 0) {
        if (
          (this.returnFrom.returnRemark === undefined) |
          (this.returnFrom.returnRemark === null)
        ) {
          return this.$message.error("存在差异金额，备注必填");
        }
      }
      var addBankBorrowVO={
        addBankBorrowVO:this.returnFrom
      }
    console.log(addBankBorrowVO)
      if (this.type === 1) {
        //归还
        returnMoney(addBankBorrowVO).then(res => {
          this.dialogVisible = false;
          this.$parent.getBankBorrowButton();
        });
      }
      // if (this.type === 2) {
      //   //补录
      //   clearingMoney(addBankBorrowVO).then(res => {
      //     this.dialogVisible = false;
      //     this.$parent.getBankBorrowButton();
      //   });
      // }
    },
    changeMoney() {
      this.returnFrom.differMoney =
        this.returnFrom.returnMoney - this.returnFrom.borrowMoney;
    }
  }
};
</script>